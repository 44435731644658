// Fonts
@import 'fonts';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

#site-header{

    .header-logo{height: 60px;}
    .navbar-brand{color: $red; font-weight: 500;}
    .navbar-brand:hover{color: #fff;}
}

#site-footer{
    color: #999;
    font-size:14px;

    a{color: #999;}
    .social-media a{padding: 0 0.25rem}
    .social-media a:first-child{margin-left: 0;}
}

.carousel.fullpage-carousel{
    .carousel-item{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 54vw;
    }
}

.carousel-indicators-bg{
    background-color: black;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
   }
   
   .carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
   }


.page-content{
    background: url('/assets/img/page-content-bg.png') top center no-repeat;
    background-size:cover;
    padding: 3rem 0;
}

.tmp-home{
    .page-content{
        background: url('/assets/img/bg-smile.png') bottom center no-repeat, url('/assets/img/page-content-bg.png');
    }
}

#page-intro{

    min-height:40vh; 
    background-position:center center; 
    background-size:cover;
    background-color: $red;

    h1{font-size:48px; background-color: #000; color:#fff; display: inline-block; padding:1rem 1.5rem}

}

.page-accordion{
    .accordion-button{
        font-size:1.25rem;
        font-weight: 500;
        &:focus{box-shadow:none}
    }
    .accordion-item{
         &:not(:first-of-type) {
            border: $accordion-border-width solid $accordion-border-color;
        }
        &:not(:last-child){
            margin-bottom:1.5rem;
        }
    }
    .accordion-body{
        line-height: 1.8;
        h6:not(:first-child){
            margin-top: 2rem;
        }
        img{
            margin-bottom: 2rem;
        }
    }
}

.accordion-button:not(.collapsed){
    color: #f80000 !important;
}

li:focus, nav-link:focus{
    outline-color: transparent !important;
    outline-width: 0 !important;
    outline: none !important;
}

.nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
    outline: none !important;
}



$twitter: #1da1f2;
$facebook: #3b5998;
$instagram: #833ab4;
$behance: #1769ff;
$linkedin: #0077b5;

.social-media{
    font-size:20px;
    
    a{padding: 0 0.5rem;}

    a.linkedin:hover{color:$linkedin !important}
    a.twitter:hover{color:$twitter !important}
    a.behance:hover{color:$behance !important}
    a.instagram:hover{color:$instagram !important}
    a.facebook:hover{color:$facebook !important}
}


@media(min-width: 992px){
    .carousel.fullpage-carousel{
        .carousel-item{
            height: calc(100vh - 76px);
        }
    }
    .dropdown-menu{
        top: 55px !important;
        left: 0px !important;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        box-shadow: 0px 4px 20px rgb(30 30 30 / 69%);
    }

    .nav-item-right-border{
        border-right: 1px solid #ffffff7a;
        position: absolute;
        height: 55px;
        width: 2px;
        right: 0;
        bottom: -17px;
    }

    .nav-link::after{
        border: none !important;
    }
}
