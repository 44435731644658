@font-face {
  font-family: "GothamRounded";
  font-style: normal;
  src: url("/fonts/GothamRoundedLight.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "GothamRounded";
  font-style: normal;
  src: url("/fonts/GothamRoundedBook.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "GothamRounded";
  font-style: normal;
  src: url("/fonts/GothamRoundedMedium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "GothamRounded";
  font-style: normal;
  src: url("/fonts/GothamRoundedBold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}